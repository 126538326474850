<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-12 col-lg-12 col-xl-6">
        <h5 class="blue-text font-weight-bold">ステージング一覧</h5>
      </div>
      <div class="col-12 col-md-12 col-lg-12 col-xl-6">
        <div id="top-ation">
          <div class="align-middle search-label text-right center">
            <span class="font-weight-bold">フィルター:</span>
          </div>
          <div class="search mr-4 no-border">
            <b-form-select
              class="shadow-1"
              v-model="filter"
              :options="getFilterOptions()"
              v-on:change="getResults()"
              required
            ></b-form-select>
          </div>
          <div class="search shadow-1">
            <div class="icon">
              <font-awesome-icon
                :icon="{ prefix: 'fas', iconName: 'search' }"
              />
            </div>
            <input
              type="text"
              placeholder="検索"
              v-model="search"
              v-on:keyup="searchData"
            />
          </div>
        </div>
      </div>
      <div class="col-12">
        <div id="staging-list" class="mt-5" v-if="stagingListing">
          <div v-if="stagingListing.length > 0">
            <div
              class="row m-0 pt-3 pb-3 mb-2 item"
              v-for="(el, i) in stagingListing"
              :key="i"
            >
              <div class="col-12 col-md-12 col-lg-12 col-xl-9">
                <div class="row details">
                  <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                    <div class="row">
                      <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                        <p class="mb-1 grey-text font-weight-bold">注文ID</p>
                        <p class="ellipsis-1">{{ el.reference_no }}</p>
                      </div>
                      <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                        <p class="mb-1 grey-text font-weight-bold">注文する</p>
                        <router-link
                          :to="{
                            name: 'adminRoute-users-info',
                            params: {
                              id: el.user_id,
                            },
                          }"
                        >
                        <p class="ellipsis-1" v-if="el.user.user_profile">
                          {{ formatUserName(el.user) }}
                        </p>
                        </router-link>
                      </div>
                      <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                        <p class="mb-1 grey-text font-weight-bold">枚数</p>
                        <p class="ellipsis-1">
                          {{
                            el.request_items.length === 1
                              ? `${el.request_items.length} 枚`
                              : `${el.request_items.length} 枚`
                          }}
                        </p>
                      </div>
                      <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                        <p class="mb-1 grey-text font-weight-bold">注文日</p>
                        <p class="ellipsis-1">
                          {{
                            el.billing ? formatDate(el.billing.updated_at) : 'N/A'
                          }}
                        </p>
                      </div>
                      <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                        <p class="mb-1 grey-text font-weight-bold">合計金額</p>
                        <p class="ellipsis-1">
                          {{
                            el.billing ? formatPrice(el.billing.amount) : 'N/A'
                          }}
                        </p>
                      </div>
                      <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                        <p class="mb-1 grey-text font-weight-bold">注文状況</p>
                        <p class="ellipsis-1 green-text" v-if="el.status === 3">
                          <font-awesome-icon
                            :icon="{ prefix: 'fas', iconName: 'circle' }"
                          />
                          <span class="ml-2">確認済み</span>
                        </p>
                        <p
                          class="ellipsis-1 yellow-text"
                          v-else-if="el.status === 2"
                        >
                          <font-awesome-icon
                            :icon="{ prefix: 'fas', iconName: 'circle' }"
                          />
                          <span class="ml-2">納品済み</span>
                        </p>
                        <p
                          class="ellipsis-1 grey-text"
                          v-else-if="el.status === 6"
                        >
                          <font-awesome-icon
                            :icon="{ prefix: 'fas', iconName: 'circle' }"
                          />
                          <span class="ml-2">キャンセル</span>
                        </p>
                        <p class="ellipsis-1 red-text" v-else>
                          <font-awesome-icon
                            :icon="{ prefix: 'fas', iconName: 'circle' }"
                          />
                          <span class="ml-2">納品前</span>
                        </p>
                      </div>
                      <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                        <p class="mb-1 grey-text font-weight-bold">決済方法</p>
                        <p class="ellipsis-1" v-if="el.billing && el.billing.is_invoice_payment == '0'">
                          {{
                            'クレジット払い'
                          }}
                        </p>
                        <p class="ellipsis-1" v-else>
                          {{
                            '請求書払い'
                          }}
                        </p>
                      </div>

                      <div class="col-6 col-md-3 col-lg-3 col-xl-4 mb-2">
                        <p class="mb-1 grey-text font-weight-bold">注文カテゴリ</p>
                        <p v-if="el.request_category == 1" class="m-0 ellipsis-1">
                          デジタルステージング
                        </p>
                        <p v-if="el.request_category == 2" class="m-0 ellipsis-1">
                          360°デジタルステージング
                        </p>
                        <p v-if="el.request_category == 3" class="m-0 ellipsis-1">
                          家具消し
                        </p>
                        <p v-if="el.request_category == 4" class="m-0 ellipsis-1">
                          商業用デジタルステージング
                        </p>
                        <p v-if="el.request_category == 5" class="m-0 ellipsis-1">
                          デジタルリノベーション
                        </p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-12 col-lg-12 col-xl-3">
                <div class="row actions">
                  <div class="col-12 mb-3">
                    <router-link
                      :to="{
                        name: 'adminRoute-staging-view',
                        params: {
                          id: el.id,
                        },
                      }"
                    >
                      <button class="btn btn-ds blue white-text" v-waves.light>
                        詳細を表示
                      </button>
                    </router-link>
                  </div>
                  <div class="col-12 mb-3" v-if="el.status !== 6">
                    <button
                      v-if="el.status === 3"
                      class="btn btn-ds blue white-text"
                      v-waves.light
                      @click="downloadImages(el.request_items)"
                    >
                      画像をダウンロード
                    </button>
                    <router-link
                      v-else-if="el.status === 2"
                      :to="{
                        name: 'adminRoute-staging-correction',
                        params: {
                          id: el.id,
                        },
                      }"
                    >
                      <button class="btn btn-ds blue white-text" v-waves.light>
                        修正リクエスト
                      </button>
                    </router-link>
                    <!-- <button
                      v-else
                      class="btn btn-ds white blue-outline blue-text"
                      v-waves.light
                      v-on:click="setCancelItemModal(el.id)"
                    >
                      キャンセル
                    </button> -->
                  </div>
                  <div class="col-12 mb-3" v-if="el.status !== 6">
                      <button
                        v-if="el.status === 3"
                        class="btn btn-ds blue white-text"
                        v-waves.light
                        @click="undoCompletion(el.id)"
                      >
                        完了を取り消す
                      </button>
                    </div>
                </div>
              </div>
            </div>
            <pagination
              class="mt-3 mb-0 mx-0"
              :data="pagination.data"
              :show-disabled="pagination.showDisabled"
              :size="pagination.size"
              :align="pagination.align"
              :limit="1"
              @pagination-change-page="paginateData"
            />
          </div>
          <div v-else>
            <b-card class="shadow-1 pt-5 pb-5 text-center" no-header no-footer>
              <img src="/img/icons/ico-info.svg" alt="ico-info" />
              <h3 class="m-0 mt-3 grey-text">表示できる内容がありません。</h3>
            </b-card>
          </div>
        </div>
        <div class="mt-5" v-else>
          <div class="row m-0 pt-3 pb-3 item" v-for="(el, i) in 10" :key="i">
            <div class="col-12 col-md-12 col-lg-12 col-xl-9">
              <div class="row details">
                <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                  <div class="row">
                    <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-4">
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="80%"></b-skeleton>
                    </div>
                    <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-4">
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="50%"></b-skeleton>
                    </div>
                    <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-4">
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="70%"></b-skeleton>
                    </div>
                    <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-4">
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="50%"></b-skeleton>
                    </div>
                    <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-4">
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="80%"></b-skeleton>
                    </div>
                    <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-4">
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="80%"></b-skeleton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-3">
              <div class="row actions">
                <div class="col-12 mb-2">
                  <b-skeleton
                    type="button"
                    height="56px"
                    width="100%"
                  ></b-skeleton>
                </div>
                <div class="col-12 mb-2">
                  <b-skeleton
                    type="button"
                    height="56px"
                    width="100%"
                  ></b-skeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="cancel-item-modal"
      class="border-radius-2"
      size="md"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <h5
        class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
        v-on:click="$bvModal.hide('cancel-item-modal')"
      >
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
      </h5>
      <div class="container text-center">
        <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
        <h5 class="mt-4 mb-0 dark-blue-text font-weight-bold">
          この依頼をキャンセルしてもよろしいですか？
        </h5>
        <div class="row mb-3 mt-5">
          <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-2">
            <button
              class="btn btn-ds light-grey white-text pt-2 pb-2"
              type="button"
              v-waves.light
              v-on:click="$bvModal.hide('cancel-item-modal')"
            >
              <span>キャンセル</span>
            </button>
          </div>
          <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-2">
            <button
              class="btn btn-ds dark-blue white-text pt-2 pb-2"
              type="button"
              v-waves.light
              v-on:click="removeItem"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="undo-completion-modal"
      class="border-radius-2"
      size="md"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <div class="container text-center">
        <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
        <h5 class="mt-4 mb-0 dark-blue-text font-weight-bold">
          完了を取り消してもよろしいですか？
        </h5>
        <div class="row mb-3 mt-5">
          <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-2">
            <button
              class="btn btn-ds light-grey white-text pt-2 pb-2"
              type="button"
              v-waves.light
              v-on:click="$bvModal.hide('undo-completion-modal')"
            >
              <span>キャンセル</span>
            </button>
          </div>
          <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-2">
            <button
              class="btn btn-ds dark-blue white-text pt-2 pb-2"
              type="button"
              v-waves.light
              @click="undoConfirmed"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// Import Services
import { globalService_DownloadIMG } from '../../services/global';
import { adm_stagingService_GetAllSR, adm_cancel_completion } from '../../services/admin/staging';
import { cus_stagingService_DeleteSR } from '../../services/customer/staging';
// Import Moment JS
import moment from 'moment';
//import common
import { getRequestItemVersions } from '../../common/Functions';
import { formatDate, formatPrice, formatUserName } from '../../common/Utility';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Staging | List',
    };
  },

  data() {
    return {
      search: '',
      stagingListing: null,
      filter: null,
      pagination: {
        data: null,
        showDisabled: false,
        size: 'default',
        align: 'center',
      },
      stagingId: null,
      itemToBeRemoved: null,
      isMix:false,
    };
  },
  watch: {
    $route() {
      this.getResults(this.getUrlQueryParamsPage());
    },
  },
  mounted() {
    this.getResults(this.getUrlQueryParamsPage());
  },
  methods: {
    formatDate,
    formatPrice,
    formatUserName,
    getUrlQueryParamsPage() {
      let currentRouteURLPrams = new URLSearchParams(location.search);
      return currentRouteURLPrams.get('page');
    },
    getResults(page) {
      let _this = this;

      let accessToken = localStorage.getItem('_accessToken');

      _this.stagingListing = null;

      adm_stagingService_GetAllSR({
        page: page ? page : 1,
        token: accessToken,
        status: _this.filter ? _this.filter : '',
        search: _this.search ? _this.search : '',
      })
        .then((response) => {
          let data = response.data.result;
          console.log(data);

          _this.stagingListing = data.data;
          _this.pagination.data = data;
        })
        .catch((error) => {
          console.log(error);
          _this.$bvToast.toast(
            'データの取得中にエラーが起こりました。再度お試しください。',
            {
              title: 'システムエラー',
              autoHideDelay: 5000,
              variant: 'danger',
              appendToast: true,
            }
          );
        });

      _this.$store.state.currentPaginationPage = page;
    },
    paginateData(page) {
      let _this = this;
      let currentRouteURLPrams = new URLSearchParams(location.search);
      let url_page = currentRouteURLPrams.get('page');
      if (url_page != page) {
        _this.$router.push({
          name: 'adminRoute-staging-list',
          query: { page: page },
        });
      }
    },
    searchData(e) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getResults();
      }, 600);
    },
    setCancelItemModal(id) {
      this.itemToBeRemoved = id;
      this.$bvModal.show('cancel-item-modal');
    },

    removeItem() {
      let _this = this;

      _this.$bvModal.hide('cancel-item-modal');

      _this.$store.state.modalLoaderMessage = '削除中';
      _this.$bvModal.show('modal-loader');

      let accessToken = localStorage.getItem('_accessToken');

      console.log(_this.itemToBeRemoved, accessToken);
      cus_stagingService_DeleteSR({
        id: _this.itemToBeRemoved,
        token: accessToken,
      })
        .then((response) => {
          console.log(response);

          _this.getResults();

          _this.$bvToast.toast('You have successfully canceled the item.', {
            title: 'キャンセルに成功しました',
            autoHideDelay: 5000,
            variant: 'success',
            appendToast: true,
          });

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        })
        .catch((error) => {
          console.log(error);

          _this.$bvToast.toast(
            '依頼の削除中にエラー起こりました。再度お試しください。',
            {
              title: 'システムエラー',
              autoHideDelay: 5000,
              variant: 'danger',
              appendToast: true,
            }
          );

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        });
    },
    getFilterOptions() {
      return [
        {
          value: null,
          text: '全ての注文状況',
        },
        {
          value: 1,
          text: 'Pending',
        },
        {
          value: 2,
          text: 'Delivered',
        },
        {
          value: 3,
          text: 'Confirmed',
        },
        {
          value: 5,
          text: 'For Approval',
        },
        {
          value: 6,
          text: 'Cancelled',
        },
      ];
    },
    downloadImages(requestItems) {
      let link = document.createElement('a');
      document.body.appendChild(link);

      let accessToken = localStorage.getItem('_accessToken');

      //download current confirmed image for each request items
      requestItems.forEach((ri) => {
        const { current_version } = getRequestItemVersions(ri);
        globalService_DownloadIMG({
          token: accessToken,
          path: current_version.result_file_path,
          isMix:this.isMix,
        }).then((response) => {
          let url = window.URL.createObjectURL(response.data);
          link.href = url;
          link.setAttribute(
            'download',
            `SR-${ri.id}_current_version_${current_version.delivery_date}`
          );
          link.click();
        });
      });
    },
    undoCompletion(stagingId) {
      this.$bvModal.show('undo-completion-modal');
      this.stagingId = stagingId
    },
    undoConfirmed() {
      let _this = this;

      _this.$bvModal.hide('undo-completion-modal');

      let accessToken = localStorage.getItem('_accessToken');
      console.log(this.stagingId)
      adm_cancel_completion({
        token: accessToken,
        stagingId: this.stagingId,
      })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.log(error);

        _this.$bvToast.toast(
          '完了取り消しの際にエラーが発生しました',
          {
            title: 'システムエラー',
            autoHideDelay: 5000,
            variant: 'danger',
            appendToast: true,
          }
        );

        setTimeout(() => {
          _this.$bvModal.hide('modal-loader');
        }, 500);
      });
    }
  },
};
</script>

<style scoped>
/* Init Style */
/* Custom Style */
#top-ation {
  display: flex;
}

#top-ation .search {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid #d1d1d1;
}

#top-ation .search .icon {
  font-size: 24px;
  padding: 10px;
  text-align: center;
  background: #fff;
  color: #d1d1d1;
}

#top-ation .search input {
  width: 100%;
  border: none;
  padding: 10px;
}

#top-ation .search input:focus {
  outline: none;
}

#staging-list .item {
  margin-bottom: 20px;
  border-radius: 20px;
  background: #f5f5f5;
}
#top-ation .search-label {
  width: 250px;
  margin-right: 20px;
}
#staging-list .item .details .img-carousel {
  height: 130px;
  background: #e9e9e9;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.center {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}
.no-border {
  border: none !important;
}
@media only screen and (max-width: 767px) {
  #top-ation {
    display: block;
  }
  #top-ation .search {
    margin-bottom: 14px;
  }
  #staging-list .item .details .img-carousel {
    height: 250px;
  }
}

@media only screen and (max-width: 1199px) {
  #staging-list .item .details {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  #staging-list .item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
  #staging-list .item .details .img-carousel {
    height: 350px;
  }
}
</style>
